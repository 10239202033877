const documentsMessages = {
    trashDocuments({ numberOfDocs, fileName, }) {
        let successMessage = [
            'messages.documents.trashDocumentsSuccess',
            {
                fileName,
            },
        ];
        if (numberOfDocs > 1) {
            successMessage = [
                'messages.documents.trashDocumentMultipleSuccess',
                {
                    numberOfDocs,
                },
            ];
        }
        return {
            successMessage: {
                message: successMessage,
                icon: 'trash-2',
            },
            errorMessage: {
                message: [
                    'messages.documents.trashDocumentsFailed',
                    { count: numberOfDocs },
                ],
            },
        };
    },
    permanentlyDeleteDocuments({ numberOfDocs, fileName, }) {
        let successMessage = [
            'messages.documents.permanentlyDeleteDocumentsSuccess',
            {
                fileName,
            },
        ];
        if (numberOfDocs > 1) {
            successMessage = [
                'messages.documents.permanentlyDeleteDocumentsMultipleSuccess',
                {
                    numberOfDocs,
                },
            ];
        }
        return {
            successMessage: {
                message: successMessage,
                icon: 'trash-2',
            },
            errorMessage: {
                message: [
                    'messages.documents.permanentlyDeleteDocumentsFailed',
                    { count: numberOfDocs },
                ],
            },
        };
    },
    copyDocument(fileName) {
        return {
            successMessage: {
                message: [
                    'messages.documents.copyDocumentSuccess',
                    {
                        fileName,
                    },
                ],
                icon: 'copy',
            },
            errorMessage: {
                message: [
                    'messages.documents.copyDocumentFailed',
                    {
                        fileName,
                    },
                ],
            },
        };
    },
    copyMultipleDocuments(numberOfDocs) {
        return {
            successMessage: {
                message: ['messages.documents.copyMultipleSuccess', { numberOfDocs }],
                icon: 'copy',
            },
            errorMessage: {
                message: ['messages.documents.copyMultipleFailed', { numberOfDocs }],
            },
        };
    },
    unTrashDocument(numberOfDocs) {
        return {
            successMessage: {
                message: [
                    'messages.documents.unTrashDocumentSuccess',
                    { count: numberOfDocs },
                ],
                icon: 'refresh-ccw',
            },
            errorMessage: {
                message: [
                    'messages.documents.unTrashDocumentFailed',
                    { count: numberOfDocs },
                ],
            },
        };
    },
    moveDocumentsToTrash(numberOfDocs, fileName) {
        return {
            successMessage: {
                message: numberOfDocs > 1 ?
                    [
                        'messages.documents.moveDocumentsToTrashMultipleSuccess',
                        {
                            numberOfDocs,
                        },
                    ] :
                    [
                        'messages.documents.moveDocumentsToTrashSuccess',
                        {
                            fileName,
                        },
                    ],
                icon: 'trash-2',
            },
            errorMessage: {
                message: 'messages.documents.moveDocumentsToTrashFailed',
            },
        };
    },
    shareDocuments(numberOfDocs) {
        return {
            successMessage: {
                message: [
                    'messages.documents.shareDocumentsSuccess',
                    { count: numberOfDocs },
                ],
                icon: 'share-2',
            },
            errorMessage: {
                message: [
                    `messages.documents.shareDocumentsFailed`,
                    {
                        numberOfDocs,
                    },
                ],
            },
        };
    },
    getDocuments() {
        return {
            errorMessage: {
                message: 'messages.documents.getDocumentsFailed',
            },
        };
    },
    getDocument(fileName) {
        return {
            errorMessage: {
                message: [
                    'messages.documents.getDocumentFailed',
                    {
                        fileName,
                    },
                ],
            },
        };
    },
    updateDocumentCategories() {
        return {
            errorMessage: {
                message: 'messages.documents.updateDocumentCategoriesFailed',
            },
        };
    },
    updateDocumentLabels() {
        return {
            errorMessage: {
                message: 'messages.documents.updateDocumentLabelsFailed',
            },
        };
    },
    updateDocumentFileName() {
        return {
            errorMessage: {
                message: 'messages.documents.updateDocumentFileNameFailed',
            },
        };
    },
    previewDocument(fileName) {
        return {
            errorMessage: {
                message: [
                    'messages.documents.previewDocumentFailed',
                    {
                        fileName,
                    },
                ],
            },
        };
    },
    unshareDocument(fileName) {
        return {
            errorMessage: {
                message: [
                    'messages.documents.unshareDocumentFailed',
                    {
                        fileName,
                    },
                ],
            },
        };
    },
    acceptDocuments(count) {
        return {
            successMessage: ({ acceptedDocuments, failedDocuments, }) => {
                if (acceptedDocuments.length > 0 && failedDocuments.length > 0) {
                    return {
                        message: [
                            'messages.documents.acceptDocumentsPartialSuccess',
                            {
                                accepted: acceptedDocuments.length,
                                failed: failedDocuments.length,
                            },
                        ],
                    };
                }
                if (failedDocuments.length > 0) {
                    return {
                        message: [
                            'messages.documents.acceptDocumentsFailed',
                            { count: failedDocuments.length },
                        ],
                    };
                }
                return {
                    message: ['messages.documents.acceptDocumentsSuccess', { count }],
                };
            },
            errorMessage: {
                message: ['messages.documents.acceptDocumentsFailed', { count }],
            },
        };
    },
    rejectDocuments(count) {
        return {
            successMessage: {
                message: ['messages.documents.rejectDocumentsSuccess', { count }],
            },
            errorMessage: {
                message: ['messages.documents.rejectDocumentsFailed', { count }],
            },
        };
    },
    handleDocuments(numberOfAcceptedDocs) {
        return {
            successMessage: {
                message: [
                    'messages.documents.acceptDocumentsSuccess',
                    { count: numberOfAcceptedDocs },
                ],
            },
            errorMessage: {
                message: 'messages.documents.handleDocumentsFailed',
            },
        };
    },
    getDocumentLabels() {
        return {
            errorMessage: {
                message: 'messages.documents.getDocumentLabelsFailed',
            },
        };
    },
};
export default documentsMessages;
