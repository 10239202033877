import { EventAction } from './events/actions';
import { EventCategory } from './events/categories';
import { CustomDimensionId } from './events/customDimensions';
import { EventNames } from './events/names';
export const Tracker = {
    Action: EventAction,
    Category: EventCategory,
    Name: EventNames,
    CustomDimensionId,
};
export { CustomDimensionId as TrackingCustomDimensionId, EventAction as TrackingEventAction, EventCategory as TrackingEventCategory, EventNames as TrackingEventNames, };
