import { jsx as _jsx } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import { IconButton } from '@chakra-ui/react';
import FeatherIcon from '../icon/FeatherIcon';
const NavigationMenuToggle = ({ setIsOpen, isOpen }) => {
    const { t } = useTranslation('shared');
    return (_jsx(IconButton, { display: { base: 'inline-flex', md: 'none' }, "aria-label": isOpen ? t('navigation.hideNav') : t('navigation.showNav'), "aria-expanded": isOpen, variant: 'menuIcon', background: 'transparent', icon: isOpen ?
            (_jsx(FeatherIcon, { name: 'x', stroke: 'neutral400', size: 28 })) :
            (_jsx(FeatherIcon, { name: 'menu', color: 'neutral400' })), title: isOpen ? t('navigation.hideNav') : t('navigation.showNav'), onClick: () => setIsOpen(!isOpen) }));
};
export default NavigationMenuToggle;
