import { useMemo } from 'react';
import { useInfiniteQuery } from '@tanstack/react-query';
import { historyLogQueryKeys } from './cache';
import { getHistoryLogItems } from './historyLogItems';
const pageSize = 20;
const createHistoryLogModule = ({ historyLogApi, }) => {
    const usePagedHistoryLogsV3 = (params = {}) => {
        const { data, isLoading, error, fetchNextPage, isFetchingNextPage, hasNextPage, refetch, } = useInfiniteQuery({
            queryKey: historyLogQueryKeys.getHistoryLogs(params?.documentIds ?? params),
            initialPageParam: 0,
            queryFn: ({ pageParam }) => historyLogApi.getHistoryLogsV3({
                offset: Number(pageParam),
                limit: pageSize,
                ...params,
            }),
            getNextPageParam: (lastPage, allPages) => {
                const totalFetchedDocuments = allPages.flatMap(page => page.historyLogs).length;
                return totalFetchedDocuments < lastPage.total ?
                    allPages.length * pageSize :
                    undefined;
            },
        });
        const allHistoryLogs = useMemo(() => data?.pages.flatMap(page => page.historyLogs), [data?.pages]);
        return {
            getHistoryLogItems,
            historyLogs: allHistoryLogs,
            total: data?.pages[0].total,
            error,
            isLoading,
            fetchNextPage,
            isFetchingNextPage,
            hasNextPage,
            refetch,
        };
    };
    return {
        usePagedHistoryLogsV3,
    };
};
export default createHistoryLogModule;
