import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, CircularProgress, Flex, Td, Text, Tr } from '@chakra-ui/react';
import * as Sentry from '@sentry/react';
import { isCertinotError, useRemoveDocumentUploadEntity, useUpdateUploadEntity, } from '@izimi/core';
import { Tracker, useModules } from '@izimi/modules';
import { formatBytes } from '@izimi/utils';
import { CustomIcon } from '../../icon';
import { useTracking } from '../../tracking';
export const DocumentTableRowUpload = ({ documentUploadEntity, maxSizeFormatted, }) => {
    const { t } = useTranslation('shared');
    const { 
    // @ts-expect-error extended
    documents: { useUploadDocument }, public: { useWebConfiguration }, } = useModules();
    const { trackEvent } = useTracking();
    const { configuration } = useWebConfiguration();
    const { uploadDocument, progress, isPending: isPendingUploadDocument, cancelUpload, } = useUploadDocument();
    const removeDocumentUploadEntity = useRemoveDocumentUploadEntity();
    const updateUploadEntity = useUpdateUploadEntity();
    const [errorMessage, setErrorMessage] = useState('');
    const hasError = errorMessage.length > 0;
    useEffect(() => {
        return () => cancelUpload();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const handleDocumentUpload = useCallback(async () => {
        try {
            setErrorMessage('');
            updateUploadEntity(documentUploadEntity.fileId, { uploadStarted: true });
            await uploadDocument({
                file: documentUploadEntity.file,
                categories: documentUploadEntity.categories,
                labels: documentUploadEntity.labels,
                isShareable: documentUploadEntity.isShareable,
            });
            updateUploadEntity(documentUploadEntity.fileId, { uploadSuccess: true });
        }
        catch (error) {
            updateUploadEntity(documentUploadEntity.fileId, {
                uploadAborted: true,
            });
            if (error.message === 'client.technical.uploadAborted') {
                setErrorMessage(t('vault.upload.aborted'));
                return;
            }
            // errors thrown from different source than API will not have a userMessage
            if (!error.userMessage) {
                Sentry.withScope((scope) => {
                    scope.setTags({
                        description: 'Upload document failed due to internal error',
                    });
                    Sentry.captureException(error);
                });
                setErrorMessage(t('vault.upload.notUploadedBecauseInternalError'));
                return;
            }
            if (isCertinotError(error)) {
                switch (error.userMessage) {
                    case 'client.technical.vaultlimitexceeded':
                        setErrorMessage(t('vault.upload.vaultLimitExceeded', {
                            size: maxSizeFormatted,
                        }));
                        break;
                    case 'client.technical.unsupportedfiletype':
                        setErrorMessage(t('vault.upload.unsupportedFiletype', {
                            type: `.${documentUploadEntity.extension}`,
                        }));
                        break;
                    case 'client.technical.unsupportedmedia':
                        setErrorMessage(t('vault.upload.unsupportedMedia', {
                            type: `.${documentUploadEntity.extension}`,
                        }));
                        break;
                    case 'client.technical.maxfilesizeexceeded':
                        setErrorMessage(t('vault.upload.maxfileSizeExceeded', {
                            size: formatBytes(configuration?.vaultConfig
                                .maxAllowedFileSizeInBytes),
                        }));
                        break;
                    case 'client.technical.validation':
                        setErrorMessage(t('vault.upload.documentNameInvalid'));
                        break;
                    default:
                        setErrorMessage(t('vault.upload.notUploadedBecauseInternalError'));
                }
            }
            else {
                setErrorMessage(t('vault.upload.notUploadedBecauseInternalError'));
            }
        }
    }, [
        documentUploadEntity,
        updateUploadEntity,
        configuration,
        t,
        maxSizeFormatted,
        uploadDocument,
    ]);
    const handleClientErrors = useCallback(() => {
        if (!documentUploadEntity.fileTypeAllowed) {
            return setErrorMessage(t('vault.upload.unsupportedFiletype', {
                type: `.${documentUploadEntity.extension}`,
            }));
        }
        if (!documentUploadEntity.fileSizeAllowed) {
            return setErrorMessage(t('vault.upload.maxfileSizeExceeded', {
                size: formatBytes(configuration?.vaultConfig.maxAllowedFileSizeInBytes),
            }));
        }
        return setErrorMessage(t('vault.upload.notUploadedBecauseInternalError'));
    }, [
        configuration?.vaultConfig.maxAllowedFileSizeInBytes,
        documentUploadEntity.fileTypeAllowed,
        documentUploadEntity.extension,
        documentUploadEntity.fileSizeAllowed,
        t,
    ]);
    useEffect(() => {
        if (documentUploadEntity.uploadSuccess &&
            documentUploadEntity.uploadStarted &&
            documentUploadEntity.isValid) {
            trackEvent({
                category: Tracker.Category.Uploading,
                action: Tracker.Action.UploadedDocument,
                value: 1,
            });
        }
        if (documentUploadEntity.isValid &&
            !documentUploadEntity.uploadSuccess &&
            !documentUploadEntity.uploadStarted) {
            handleDocumentUpload();
        }
        if (!documentUploadEntity.isValid && !documentUploadEntity.uploadSuccess) {
            handleClientErrors();
        }
        // trackEvent is not pure
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        handleDocumentUpload,
        documentUploadEntity.isValid,
        documentUploadEntity.uploadSuccess,
        handleClientErrors,
    ]);
    const icon = useMemo(() => {
        if (!documentUploadEntity.isValid ||
            documentUploadEntity.uploadAborted ||
            hasError) {
            return (_jsx(CustomIcon, { name: 'frown', color: 'secondary200', secondaryColor: 'secondary400' }));
        }
        if (documentUploadEntity.uploadSuccess) {
            return (_jsx(CustomIcon, { name: 'check', color: 'primary500', secondaryColor: 'primary300' }));
        }
        return _jsx(CircularProgress, { color: 'primary500', size: '28px', isIndeterminate: true });
    }, [
        documentUploadEntity.isValid,
        documentUploadEntity.uploadAborted,
        documentUploadEntity.uploadSuccess,
        hasError,
    ]);
    return (_jsxs(Tr, { children: [_jsx(Td, { px: 0, children: _jsxs(Flex, { alignItems: 'center', children: [_jsx(Flex, { shrink: 0, children: isPendingUploadDocument ?
                                (_jsx(CircularProgress, { color: 'primary500', value: progress, size: '28px' })) :
                                (icon) }), _jsxs(Flex, { direction: 'column', ml: 3, children: [_jsx(Text, { variant: 'tableHead', noOfLines: 1, wordBreak: 'break-word', color: hasError ? 'secondary400' : 'neutral500', children: documentUploadEntity.fileNameWithoutExtension }), hasError ?
                                    (_jsx(Text, { variant: 'small', fontWeight: 'semibold', color: 'secondary400', children: errorMessage })) :
                                    null] })] }) }), _jsxs(Td, { paddingInlineEnd: 0, textAlign: 'right', children: [!hasError &&
                        documentUploadEntity.uploadStarted &&
                        !documentUploadEntity.uploadSuccess &&
                        !documentUploadEntity.uploadAborted && (_jsx(Button, { variant: 'unstyled', role: 'button', onClick: cancelUpload, borderColor: 'primary400', title: t('action.abort'), children: _jsx(Text, { variant: 'tableHead', color: 'primary500', children: t('action.abort') }) })), hasError ?
                        (_jsx(Button, { variant: 'unstyled', role: 'button', onClick: () => removeDocumentUploadEntity(documentUploadEntity.fileId), borderColor: 'secondary400', title: t('vault.upload.remove'), children: _jsx(Text, { variant: 'tableHead', color: 'secondary500', children: t('vault.upload.remove') }) })) :
                        null] })] }));
};
