export const getNameFromPath = (path) => path ? path.replace(/.*\//, '') : '';
export const getExtension = (filename) => {
    const fileParts = filename.split('.');
    if (fileParts?.length > 1) {
        return fileParts[fileParts.length - 1].toString().toLowerCase();
    }
    return '';
};
export const getNameWithoutExtension = (filename) => filename.slice(0, filename.lastIndexOf('.'));
export const normalizeFilePath = (filePath) => {
    const filePrefix = 'file://';
    if (filePath.startsWith(filePrefix)) {
        return filePath.substring(filePrefix.length);
    }
    return filePath;
};
export const megabytesInBytes = (megabytes) => 1024 * 1024 * megabytes;
export const isLargeFile = (size) => size >= megabytesInBytes(10);
export const formatBytes = (bytes, decimals = 0) => {
    if (bytes === 0)
        return '0 Bytes';
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return `${Number.parseFloat((bytes / k ** i).toFixed(dm))}${sizes[i]}`;
};
