import { Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import useTracking from './useTracking';
const PageTracker = ({ children }) => {
    const { trackPageView } = useTracking();
    const location = useLocation();
    useEffect(() => {
        trackPageView(location.pathname);
    }, [location.pathname, trackPageView]);
    return _jsx(_Fragment, { children: children });
};
export default PageTracker;
