import * as Sentry from '@sentry/react';
import i18n from 'i18next';

import { getUserFromStorage, logout } from '@izimi/auth';
import {
  ConnectionsApi,
  ContactsApi,
  DocumentsApi,
  FetchService,
  HistoryLogApi,
  NabanApi,
  NewsApi,
  NotaryApi,
  NotificationsApi,
  PostalCodesApi,
  PublicApi,
  TermsAndConditionsApi,
  UserApi,
  UserMessagesApi,
  VaultApi,
} from 'api';

import packageJSON from '../../package.json';
import config from './config';

const getSupportId = ({ value }: { value: string }) => value.match(/\{\{(.*?)\}\}/)?.[1];

const handleFirewallError = async (res: Response) => {
  try {
    const supportId = getSupportId({ value: await res.text() });
    if (supportId && /^(?:\w|-){0,40}$/.test(supportId)) {
      Sentry.captureMessage('F5 Firewall Error', {
        tags: {
          type: 'api',
          supportId,
        },
      });
    }
  } catch (e) {
    Sentry.captureException(e);
  }
};

export const fetchService = new FetchService({
  getAccessToken: () => Promise.resolve(getUserFromStorage()?.access_token),
  getLanguage: () => i18n.language,
  getAppSpec: () => ({ app: 'web-citizen', version: packageJSON.version }),
  handleErrorResponse(res) {
    const isForbiddenOrUnauthorized = res.status === 401 || res.status === 403;

    // An error might be triggered if F5 responds with a 200 status and an HTML page in the body.
    // In such cases, we want to log an F5 error event.
    if (res.status === 200 && 'headers' in res && res.headers.get('Content-Type')?.includes('text/html')) {
      return handleFirewallError(res);
    }

    if (
      res.status >= 400 ||
      (res instanceof XMLHttpRequest && res.responseType === 'text')
    ) {
      Sentry.withScope((scope) => {
        if (res instanceof Response) {
          scope.setTags({
            'x-trace-id': res.headers.get('x-trace-id'),
          });
        }
        scope.setExtra('data', res);
        scope.setTags({
          'http-status': res.status,
        });
        Sentry.captureException(new Error('api error'));
      });
    }

    // server side
    if (typeof window === 'undefined') {
      return Promise.reject(res);
    }

    if (isForbiddenOrUnauthorized) {
      logout();
      return Promise.reject(res);
    }

    if ('readyState' in res) {
      // this is the response of XMLHttpRequests errors (upload document)
      try {
        return JSON.parse(res.response);
      } catch {
        return res.response;
      }
    }

    return Promise.reject(res);
  },
  logRequests: import.meta.env.DEV,
});

fetchService.setApiUrl(import.meta.env.DEV ? `/api` : `${config.apiUrl}/api`);

export const newsApi = new NewsApi({
  getLanguage: () => i18n.language,
  getApiUrl: () => 'https://notaris.be/api/izimi',
  getApiKey: () => 'qcnedlyplr5jjierarzj8o5sxu7ix0md',
});

export const publicApi = new PublicApi(fetchService);
export const documentsApi = new DocumentsApi(fetchService);
export const userApi = new UserApi(fetchService);
export const postalCodesApi = new PostalCodesApi(fetchService);
export const contactsApi = new ContactsApi(fetchService);
export const notaryApi = new NotaryApi(fetchService);
export const termsAndConditionsApi = new TermsAndConditionsApi(fetchService);
export const vaultApi = new VaultApi(fetchService);
export const notificationsApi = new NotificationsApi(fetchService);
export const historyLogApi = new HistoryLogApi(fetchService);
export const connectionsApi = new ConnectionsApi(fetchService);
export const nabanApi = new NabanApi(fetchService);
export const userMessagesApi = new UserMessagesApi(fetchService);
