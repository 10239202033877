import { useCallback } from 'react';
import { create } from 'zustand';
import { ApiDocumentCategory } from '@izimi/api';
import { useModules } from '@izimi/modules';
import { getExtension } from '@izimi/utils';
import { createDocumentUploadEntity } from '../modules/documents/DocumentUploadEntity';
import { createStoreHook } from './helpers';
const initialState = {
    documentUploadEntities: [],
};
const useDocumentUploadStore = create()(set => ({
    ...initialState,
    addDocumentUploadEntities: documentUploadEntities => set(state => ({
        documentUploadEntities: [
            ...state.documentUploadEntities,
            ...documentUploadEntities,
        ].sort((a, b) => 
        // always put most recent upload on top of the list
        Date.parse(b.creationDate) - Date.parse(a.creationDate)),
    })),
    removeDocumentUploadEntity: (removeId) => set(state => ({
        documentUploadEntities: state.documentUploadEntities.filter(({ fileId }) => fileId !== removeId),
    })),
    resetDocumentUploadEntities: () => set(() => ({
        documentUploadEntities: [],
    })),
    updateUploadEntity: (updateId, value) => set((state) => {
        const otherEntities = state.documentUploadEntities.filter(({ fileId }) => fileId !== updateId);
        const originalEntity = state.documentUploadEntities.find(({ fileId }) => fileId === updateId);
        if (!originalEntity) {
            return state;
        }
        const updatedEntity = {
            ...originalEntity,
            ...value,
        };
        return {
            documentUploadEntities: [...otherEntities, updatedEntity].sort((a, b) => 
            // always put most recent upload on top of the list
            Date.parse(b.creationDate) - Date.parse(a.creationDate)),
        };
    }),
}));
const createStoreHookSelector = createStoreHook(useDocumentUploadStore);
export const useRemoveDocumentUploadEntity = createStoreHookSelector(state => state.removeDocumentUploadEntity);
export const useResetDocumentUploadEntities = createStoreHookSelector(state => state.resetDocumentUploadEntities);
export const useAddDocumentUploadEntities = createStoreHookSelector(state => state.addDocumentUploadEntities);
export const useUpdateUploadEntity = createStoreHookSelector(state => state.updateUploadEntity);
export const useDocumentUploadEntities = createStoreHookSelector(state => state.documentUploadEntities);
export const useValidateFilesAndCreateUploadEntities = (showModal, configuration, activeCategory) => {
    const { vault: { useVaultInfo }, } = useModules();
    const addDocumentUploadEntities = useAddDocumentUploadEntities();
    const { vaultInfo } = useVaultInfo();
    const isFileSizeAllowed = useCallback((file) => file.size <= Number(configuration?.vaultConfig.maxAllowedFileSizeInBytes), [configuration]);
    const isFileTypeAllowed = useCallback((file) => configuration?.allowedFileTypes.includes(`.${getExtension(file.name)}`), [configuration]);
    const validateFiles = useCallback((files) => {
        if (configuration && vaultInfo) {
            const totalUploadSize = files.reduce((acc, curr) => acc + curr.size, 0);
            const remainingVaultSize = vaultInfo.maxSize - vaultInfo.vaultSize - vaultInfo.trashSize;
            if (totalUploadSize > remainingVaultSize) {
                showModal({
                    type: 'warningVaultSizeExceeded',
                    files,
                });
                return false;
            }
        }
        return true;
    }, [configuration, showModal, vaultInfo]);
    const createUploadEntities = useCallback((files) => {
        const documentUploadEntities = files.map((file, index) => createDocumentUploadEntity({
            file,
            fileId: `${new Date().getTime()}-${index}`,
            creationDate: new Date().toUTCString(),
            categories: activeCategory && activeCategory !== ApiDocumentCategory.All ?
                [activeCategory] :
                [],
            labels: [],
            isShareable: false,
            fileSizeAllowed: isFileSizeAllowed(file),
            fileTypeAllowed: Boolean(isFileTypeAllowed(file)),
        }));
        return addDocumentUploadEntities(documentUploadEntities);
    }, [
        activeCategory,
        addDocumentUploadEntities,
        isFileSizeAllowed,
        isFileTypeAllowed,
    ]);
    const validateFilesAndCreateUploadEntities = useCallback((files) => {
        const validated = validateFiles(files);
        if (validated) {
            createUploadEntities(files);
        }
    }, [createUploadEntities, validateFiles]);
    return validateFilesAndCreateUploadEntities;
};
