import { useCallback, useMemo } from 'react';
import { useInfiniteQuery, useMutation, useQueries, useQuery, useQueryClient, } from '@tanstack/react-query';
import { nonNullable } from '@izimi/utils';
import { useInvalidateNabanDocumentsCache } from '../naban/cache';
import { useInvalidateVaultInfoCache, useInvalidateVaultPreferencesCache, } from '../vault';
import { documentsQueryKeys, useInvalidateDocumentCache, useInvalidateDocumentsSearchCache, useInvalidateDocumentsSharedByYouListCache, useInvalidateDocumentsSharedFromContactsListCache, useInvalidateDocumentsSharedFromNotaryListCache, useInvalidateDocumentsTrashListCache, useInvalidateDocumentsUnSeenListCache, useInvalidateDocumentsVaultListCache, useInvalidateDocumentsVaultTransferCache, useSetDocumentCache, useUpdatePagedDocumentsCache, } from './cache';
import { mapDocumentsToEntities, mapDocumentToEntity } from './helpers';
const STALE_30_MINUTES = 1800000;
const POLLING_RATE = 2500;
const createDocumentsModule = ({ documentsApi, }) => {
    const useDocumentFilters = (params) => {
        const { data, isLoading, error } = useQuery({
            queryKey: documentsQueryKeys.getFilters(),
            queryFn: () => documentsApi
                .getDocuments({
                limit: 1,
                offset: 0,
                returnUniqueFilterValues: true,
                ...params,
            })
                .then(res => res.uniqueFilterValues),
        });
        return {
            documentFilters: data,
            isLoading,
            error,
        };
    };
    const usePagedDocuments = ({ enabled = true, params, type, pageSize = 15, }) => {
        const sortParam = params?.sort ?? 'name,asc';
        const { data, isLoading, error, refetch, fetchNextPage, isFetchingNextPage, hasNextPage, } = useInfiniteQuery({
            queryKey: documentsQueryKeys.getDocuments(type, {
                ...params,
                sort: sortParam,
            }),
            initialPageParam: 0,
            queryFn: ({ pageParam }) => documentsApi.getDocuments({
                limit: pageSize,
                offset: Number(pageParam),
                ...params,
                sort: sortParam,
            }),
            enabled,
            staleTime: type === 'documentNameMatch' ? 0 : STALE_30_MINUTES,
            getNextPageParam: (lastPage, allPages) => {
                const totalFetchedDocuments = allPages.flatMap(page => page.documents).length;
                return totalFetchedDocuments < lastPage.total ?
                    allPages.length * pageSize :
                    undefined;
            },
        });
        const allDocuments = useMemo(() => mapDocumentsToEntities(data?.pages.flatMap(page => page.documents)), [data?.pages]);
        return {
            documents: allDocuments,
            total: data?.pages[0].total ?? 0,
            uniqueFilterValues: data?.pages[0].uniqueFilterValues,
            totalUnseen: data?.pages[0].numberUnseenDocuments,
            error,
            isLoading,
            refetch,
            fetchNextPage,
            isFetchingNextPage,
            hasNextPage,
        };
    };
    const useDocument = ({ documentId, enabled = true, }) => {
        const { data: document, isLoading, error, refetch, } = useQuery({
            queryKey: documentsQueryKeys.getDocument(documentId),
            queryFn: () => documentsApi
                .getDocument({ documentId: documentId })
                .then(mapDocumentToEntity),
            enabled: enabled && Boolean(documentId),
        });
        return {
            document,
            error,
            isLoading,
            refetch,
        };
    };
    const useGetDocuments = (ids) => {
        const result = useQueries({
            queries: ids.map(id => ({
                queryKey: documentsQueryKeys.getDocument(id),
                queryFn: () => documentsApi
                    .getDocument({ documentId: id })
                    .then(mapDocumentToEntity),
            })),
        });
        return {
            documents: result.map(r => r.data).filter(nonNullable),
            isLoading: result.some(r => r.isLoading),
        };
    };
    const useGetDocumentsScanning = ({ ids }) => {
        const { data: documents, isLoading, error, } = useQuery({
            queryKey: documentsQueryKeys.getDocuments('scanning'),
            queryFn: () => documentsApi
                .getDocuments({ ids })
                .then(res => mapDocumentsToEntities(res.documents)),
            refetchInterval: (query) => {
                if (query.state.data?.some(doc => doc.isBeingScanned)) {
                    return POLLING_RATE;
                }
                return false;
            },
        });
        return {
            documents,
            isScanning: documents?.some(doc => doc.isBeingScanned),
            isLoading,
            error,
        };
    };
    const useMoveDocumentsToTrash = () => {
        const queryClient = useQueryClient();
        const invalidateVaultInfoCache = useInvalidateVaultInfoCache();
        const updatePagedDocumentsCache = useUpdatePagedDocumentsCache();
        const invalidateNabanDocumentsCache = useInvalidateNabanDocumentsCache();
        const invalidateDocumentsVaultTransferCache = useInvalidateDocumentsVaultTransferCache();
        const invalidateVaultPreferencesCache = useInvalidateVaultPreferencesCache();
        const invalidateDocumentsUnSeenListCache = useInvalidateDocumentsUnSeenListCache();
        const invalidateDocumentsVaultListCache = useInvalidateDocumentsVaultListCache();
        const invalidateDocumentsSharedByYouListCache = useInvalidateDocumentsSharedByYouListCache();
        const invalidateDocumentsSharedFromContactsListCache = useInvalidateDocumentsSharedFromContactsListCache();
        const { mutateAsync, isPending, error } = useMutation({
            mutationFn: documentIds => documentsApi.moveDocumentsToTrash(documentIds),
            onSuccess(trashDocuments, documentIds) {
                updatePagedDocumentsCache('vaultList', (allDocuments) => {
                    return allDocuments.filter(doc => !documentIds.includes(doc.id));
                });
                updatePagedDocumentsCache('trashList', (allDocuments) => {
                    return [...trashDocuments, ...allDocuments];
                });
                // trashing documents causes the vault sizes to change
                invalidateVaultInfoCache();
                invalidateNabanDocumentsCache();
                invalidateDocumentsVaultListCache();
                invalidateDocumentsUnSeenListCache();
                invalidateDocumentsSharedByYouListCache();
                invalidateDocumentsSharedFromContactsListCache();
                // trashing documents can cause changes in vault transfer preferences
                invalidateDocumentsVaultTransferCache();
                invalidateVaultPreferencesCache();
                // Update individual document cache
                trashDocuments.forEach(trashDocument => queryClient.removeQueries({
                    queryKey: documentsQueryKeys.getDocument(trashDocument.id),
                    exact: true,
                }));
            },
        });
        return {
            moveDocumentsToTrash: mutateAsync,
            error,
            isPending,
        };
    };
    const usePermanentlyDeleteDocuments = () => {
        const invalidateVaultInfoCache = useInvalidateVaultInfoCache();
        const invalidateNabanDocumentsCache = useInvalidateNabanDocumentsCache();
        const updatePagedDocumentsCache = useUpdatePagedDocumentsCache();
        const invalidateTrashListCache = useInvalidateDocumentsTrashListCache();
        const { mutateAsync, isPending, error } = useMutation({
            mutationFn: ({ documentIds, forceDelete = false }) => documentsApi.permanentlyDeleteDocuments(documentIds, { forceDelete }),
            onSuccess: (_, { documentIds }) => {
                updatePagedDocumentsCache('trashList', (allDocuments) => {
                    return allDocuments.filter(doc => !documentIds.includes(doc.id));
                });
                // deleting documents causes the vault sizes to change
                invalidateVaultInfoCache();
                // Deleting documents might cause naban documents to change
                invalidateNabanDocumentsCache();
                // Deleting documents might need a refresh of the trash list
                invalidateTrashListCache();
            },
        });
        return {
            permanentlyDeleteDocuments: mutateAsync,
            error,
            isPending,
        };
    };
    const useEmptyTrash = () => {
        const invalidateVaultInfoCache = useInvalidateVaultInfoCache();
        const invalidateNabanDocumentsCache = useInvalidateNabanDocumentsCache();
        const updatePagedDocumentsCache = useUpdatePagedDocumentsCache();
        const { mutateAsync, isPending, error } = useMutation({
            mutationFn: () => documentsApi.permanentlyDeleteDocuments([], {
                deleteAll: true,
            }),
            onSuccess: () => {
                updatePagedDocumentsCache('trashList', () => []);
                // deleting documents causes the vault sizes to change
                invalidateVaultInfoCache();
                // emptying trash might cause naban documents to change
                invalidateNabanDocumentsCache();
            },
        });
        return {
            emptyTrash: mutateAsync,
            error,
            isPending,
        };
    };
    const useUpdateDocument = ({ onSuccess, } = {}) => {
        const queryClient = useQueryClient();
        const updatePagedDocumentsCache = useUpdatePagedDocumentsCache();
        const setDocumentCache = useSetDocumentCache();
        const { mutateAsync, isPending, error } = useMutation({
            mutationFn: ({ documentId, values }) => documentsApi.updateDocument({ documentId }, values),
            onMutate: async ({ documentId, values }) => {
                const documentQueryKey = documentsQueryKeys.getDocument(documentId);
                // Cancel any outgoing refetch
                // (so they don't overwrite our optimistic update)
                await queryClient.cancelQueries({
                    queryKey: documentQueryKey,
                });
                // Snapshot the previous value
                const previousVaultList = queryClient.getQueryData(['vaultList']);
                // Check if the document exists in cache (which might not be the case if sidebar wasn't opened)
                const document = queryClient.getQueryData(documentQueryKey);
                // different memory reference so it will not be mutated
                const previousDocument = document ?
                    Object.assign(Object.create(Object.getPrototypeOf(document)), document) :
                    undefined;
                if (document) {
                    // only update the modification date if the name has changed (backend requirement)
                    const shouldUpdateModificationDate = previousDocument?.name !== values.name;
                    // Optimistically updated document entity
                    const updatedDocument = Object.assign(document, values, {
                        modificationDate: shouldUpdateModificationDate ?
                            new Date().toUTCString() :
                            document.modificationDate,
                    });
                    // Optimistically update to the new value
                    queryClient.setQueryData(documentQueryKey, () => updatedDocument);
                    // Optimistically update the vault list cache
                    updatePagedDocumentsCache('vaultList', (allDocuments) => {
                        return allDocuments.map((doc) => {
                            if (doc.id === documentId) {
                                return {
                                    ...doc,
                                    ...values,
                                    modificationDate: shouldUpdateModificationDate ?
                                        new Date().toUTCString() :
                                        doc.modificationDate,
                                };
                            }
                            return doc;
                        });
                    });
                }
                // Return a context object with the snapshot value
                return { previousDocument, previousVaultList };
            },
            onError: (_, { documentId }, context) => {
                const documentQueryKey = documentsQueryKeys.getDocument(documentId);
                if (context?.previousDocument) {
                    queryClient.setQueryData(documentQueryKey, context.previousDocument);
                }
                if (context?.previousVaultList) {
                    queryClient.setQueryData(['vaultList'], context.previousVaultList);
                }
            },
            onSuccess(document, { documentId }) {
                setDocumentCache(mapDocumentToEntity(document));
                if (onSuccess) {
                    onSuccess(document);
                }
                else {
                    updatePagedDocumentsCache('vaultList', (allDocuments) => {
                        return allDocuments.map((doc) => {
                            if (doc.id === documentId) {
                                return document;
                            }
                            return doc;
                        });
                    });
                }
            },
        });
        return {
            updateDocument: mutateAsync,
            error,
            isPending,
        };
    };
    const useUpdateDocumentCategories = (document) => {
        const invalidateDocumentsVaultListCache = useInvalidateDocumentsVaultListCache();
        const { updateDocument, error, isPending } = useUpdateDocument({
            onSuccess: invalidateDocumentsVaultListCache,
        });
        const updateDocumentCategories = useCallback((categories) => updateDocument({
            documentId: document.id,
            values: {
                id: document.id,
                name: document.name,
                isShareable: document.isShareable,
                labels: document.labels,
                categories,
            },
        }), [updateDocument, document]);
        return {
            updateDocumentCategories,
            error,
            isPending,
        };
    };
    const useUpdateDocumentFileName = (document) => {
        const invalidateDocumentsVaultListCache = useInvalidateDocumentsVaultListCache();
        const invalidateSearchDocumentsCache = useInvalidateDocumentsSearchCache();
        const { updateDocument, error, isPending } = useUpdateDocument({
            onSuccess: () => {
                invalidateDocumentsVaultListCache();
                invalidateSearchDocumentsCache();
            },
        });
        const updateDocumentFileName = useCallback((fileName) => updateDocument({
            documentId: document.id,
            values: {
                id: document.id,
                name: fileName,
                isShareable: document.isShareable,
                labels: document.labels,
                categories: document.categories,
            },
        }), [updateDocument, document]);
        return {
            updateDocumentFileName,
            error,
            isPending,
        };
    };
    const useUpdateDocumentLabels = (document) => {
        const { updateDocument, error, isPending } = useUpdateDocument();
        const updateDocumentLabels = useCallback((labels) => updateDocument({
            documentId: document.id,
            values: {
                id: document.id,
                name: document.name,
                isShareable: document.isShareable,
                labels,
                categories: document.categories,
            },
        }), [updateDocument, document]);
        return {
            updateDocumentLabels,
            error,
            isPending,
        };
    };
    const useMarkDocumentAsSeen = () => {
        const setDocumentCache = useSetDocumentCache();
        const invalidateDocumentsUnSeenListCache = useInvalidateDocumentsUnSeenListCache();
        const invalidateDocumentsVaultListCache = useInvalidateDocumentsVaultListCache();
        const { mutateAsync, isPending, error } = useMutation({
            mutationFn: ({ documentIds, isSeen }) => documentsApi.markDocumentsSeen(documentIds, isSeen),
            onSuccess(documents) {
                invalidateDocumentsVaultListCache();
                invalidateDocumentsUnSeenListCache();
                mapDocumentsToEntities(documents).forEach(d => setDocumentCache(d));
            },
        });
        return {
            markDocumentAsSeen: mutateAsync,
            error,
            isPending,
        };
    };
    const useCopyMultipleInheritedDocuments = () => {
        const updatePagedDocumentsCache = useUpdatePagedDocumentsCache();
        const { copyDocument, isPending } = useCopyDocument();
        const copyMultipleInheritedDocuments = async (documentIds) => {
            await Promise.all(documentIds.map((documentId) => copyDocument(documentId)));
            updatePagedDocumentsCache('inheritedList', allDocuments => allDocuments.map(d => documentIds.includes(d.id) ? { ...d, isCopied: true } : d));
        };
        return {
            isPending,
            copyMultipleInheritedDocuments,
        };
    };
    const useCopyInheritedDocument = () => {
        const updatePagedDocumentsCache = useUpdatePagedDocumentsCache();
        const { copyDocument, isPending } = useCopyDocument({
            onSuccess(_, documentId) {
                updatePagedDocumentsCache('inheritedList', allDocuments => allDocuments.map(d => d.id === documentId ? { ...d, isCopied: true } : d));
            },
        });
        return {
            isPending,
            copyInheritedDocument: copyDocument,
        };
    };
    const useCopyDocument = ({ onSuccess, } = {}) => {
        const invalidateVaultInfoCache = useInvalidateVaultInfoCache();
        const invalidateDocumentsVaultListCache = useInvalidateDocumentsVaultListCache();
        const { mutateAsync, isPending, error } = useMutation({
            mutationFn: documentId => documentsApi.copyDocument({ documentId }),
            onSuccess(...args) {
                invalidateDocumentsVaultListCache();
                // copy documents causes the vault sizes to change
                invalidateVaultInfoCache();
                onSuccess?.(...args);
            },
        });
        return {
            copyDocument: mutateAsync,
            error,
            isPending,
        };
    };
    const useShareDocuments = () => {
        const updatePagedDocumentsCache = useUpdatePagedDocumentsCache();
        const invalidateDocumentsSharedByYouListCache = useInvalidateDocumentsSharedByYouListCache();
        const { mutateAsync, isPending, error } = useMutation({
            mutationFn: values => documentsApi.shareDocuments(values),
            onSuccess(documents) {
                updatePagedDocumentsCache('vaultList', (allDocuments) => {
                    return allDocuments.map((document) => {
                        const newDoc = documents.find(doc => doc.id === document.id);
                        return newDoc ?? document;
                    });
                });
                invalidateDocumentsSharedByYouListCache();
            },
        });
        return {
            shareDocuments: mutateAsync,
            error,
            isPending,
        };
    };
    const useUnShareDocuments = () => {
        const updatePagedDocumentsCache = useUpdatePagedDocumentsCache();
        const setDocumentCache = useSetDocumentCache();
        const invalidateDocumentsSharedByYouListCache = useInvalidateDocumentsSharedByYouListCache();
        const { mutateAsync, isPending, error } = useMutation({
            mutationFn: values => documentsApi.unshareDocuments(values),
            onSuccess(documents) {
                updatePagedDocumentsCache('vaultList', (allDocuments) => {
                    return allDocuments.map((document) => {
                        const newDoc = documents.find(doc => doc.id === document.id);
                        return newDoc ?? document;
                    });
                });
                invalidateDocumentsSharedByYouListCache();
                documents.forEach((document) => {
                    setDocumentCache(mapDocumentToEntity(document));
                });
            },
        });
        return {
            unShareDocuments: mutateAsync,
            error,
            isPending,
        };
    };
    const useUnTrashDocuments = () => {
        const invalidateVaultInfoCache = useInvalidateVaultInfoCache();
        const updatePagedDocumentsCache = useUpdatePagedDocumentsCache();
        const invalidateNabanDocumentsCache = useInvalidateNabanDocumentsCache();
        const invalidateDocumentsUnSeenListCache = useInvalidateDocumentsUnSeenListCache();
        const { mutateAsync, isPending, error } = useMutation({
            mutationFn: documentIds => documentsApi.unTrashDocuments(documentIds),
            onSuccess(documents, documentIds) {
                updatePagedDocumentsCache('trashList', (allDocuments) => {
                    return allDocuments.filter(doc => !documentIds.includes(doc.id));
                });
                updatePagedDocumentsCache('vaultList', (allDocuments) => {
                    return [...documents, ...allDocuments];
                });
                invalidateVaultInfoCache();
                invalidateDocumentsUnSeenListCache();
                invalidateNabanDocumentsCache();
            },
        });
        return {
            unTrashDocuments: mutateAsync,
            error,
            isPending,
        };
    };
    const useTrashDocumentIds = (total) => {
        const { documents: documentsToTrash, isLoading } = usePagedDocuments({
            params: {
                states: 'TRASHED',
            },
            type: 'trashList',
            pageSize: total,
        });
        return {
            trashDocumentIds: documentsToTrash.map(doc => doc.id),
            isLoading,
        };
    };
    const useAcceptRejectSharedDocuments = () => {
        const invalidateDocumentsSharedFromContactsListCache = useInvalidateDocumentsSharedFromContactsListCache();
        const invalidateDocumentsVaultListCache = useInvalidateDocumentsVaultListCache();
        const invalidateDocumentsSharedFromNotaryListCache = useInvalidateDocumentsSharedFromNotaryListCache();
        const invalidateDocumentCache = useInvalidateDocumentCache();
        const updatePagedDocumentsCache = useUpdatePagedDocumentsCache();
        const { mutateAsync, isPending, error } = useMutation({
            mutationFn: values => documentsApi.acceptRejectSharedDocuments(values),
            onSuccess({ acceptedDocuments, rejectedDocuments }) {
                const acceptedIds = acceptedDocuments.map(d => d.id);
                const rejectedIds = rejectedDocuments.map(d => d.documentId);
                const ids = [...acceptedIds, ...rejectedIds];
                if (ids.length > 0) {
                    ids.forEach(invalidateDocumentCache);
                }
                invalidateDocumentsVaultListCache();
                invalidateDocumentsSharedFromContactsListCache();
                invalidateDocumentsSharedFromNotaryListCache();
                updatePagedDocumentsCache('sharedFromNotaryList', (allDocuments) => {
                    return allDocuments.filter((doc) => {
                        return !ids.includes(doc.id);
                    });
                });
            },
        });
        return {
            acceptRejectSharedDocuments: mutateAsync,
            error,
            isPending,
        };
    };
    const useDocumentLabels = ({ enabled = true } = {}) => {
        const { data, isLoading, error, refetch, isSuccess } = useQuery({
            queryKey: documentsQueryKeys.getLabels(),
            queryFn: () => documentsApi.getLabels(),
            enabled,
        });
        return {
            labels: data,
            error,
            isLoading,
            refetch,
            isSuccess,
        };
    };
    const useDocumentExtendTrashDate = () => {
        const invalidateDocumentsVaultListCache = useInvalidateDocumentsVaultListCache();
        const setDocumentCache = useSetDocumentCache();
        const { mutateAsync, isPending, error } = useMutation({
            mutationFn: values => documentsApi.documentExtendTrashDate(values),
            onSuccess: (documents) => {
                invalidateDocumentsVaultListCache();
                mapDocumentsToEntities(documents).forEach(d => setDocumentCache(d));
            },
        });
        return {
            documentExtendTrashDate: mutateAsync,
            error,
            isPending,
        };
    };
    return {
        useDocumentFilters,
        usePagedDocuments,
        useDocument,
        useGetDocuments,
        useGetDocumentsScanning,
        useMoveDocumentsToTrash,
        usePermanentlyDeleteDocuments,
        useUpdateDocument,
        useUpdateDocumentCategories,
        useUpdateDocumentFileName,
        useUpdateDocumentLabels,
        useMarkDocumentAsSeen,
        useCopyInheritedDocument,
        useCopyDocument,
        useUnShareDocuments,
        useShareDocuments,
        useUnTrashDocuments,
        useAcceptRejectSharedDocuments,
        useDocumentLabels,
        useTrashDocumentIds,
        useCopyMultipleInheritedDocuments,
        useEmptyTrash,
        useDocumentExtendTrashDate,
    };
};
export default createDocumentsModule;
