import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import { IconButton, Menu, MenuButton, MenuItem, MenuList, } from '@chakra-ui/react';
import { useIsMobile } from '@izimi/hooks';
import { CustomIcon, FeatherIcon } from '../../../icon';
import { useModalStore } from '../../../modals';
import { useDocumentTrashOptions } from '../useDocumentTrashOptions';
const TrashDocumentsTableOptionsMenu = ({ document, children, ...menuProps }) => {
    const { t } = useTranslation('shared');
    const { showModal } = useModalStore();
    const isMobile = useIsMobile();
    const { handleRestoreDocument, handlePermanentlyDeleteDocument } = useDocumentTrashOptions();
    if (isMobile) {
        return (_jsx(IconButton, { title: t('action.viewOptions'), onClick: () => showModal({ type: 'trashDocumentsTableOptions', document }), icon: _jsx(FeatherIcon, { name: 'more-horizontal' }), "aria-label": t('action.viewOptions') }));
    }
    return (_jsxs(Menu, { isLazy: true, placement: 'bottom-start', offset: [-20, 0], variant: 'documentTableMenu', ...menuProps, children: [children ?? (_jsx(MenuButton, { as: IconButton, "aria-label": t('action.viewOptions'), title: t('action.viewOptions'), size: 'md', variant: 'action', icon: _jsx(FeatherIcon, { name: 'more-horizontal' }) })), _jsxs(MenuList, { children: [_jsx(MenuItem, { icon: _jsx(CustomIcon, { name: 'restore' }), onClick: () => handleRestoreDocument(document), children: t('trash:restore') }), _jsx(MenuItem, { icon: (_jsx(CustomIcon, { name: 'trash', color: 'secondary200', secondaryColor: 'secondary400' })), color: 'secondary400', onClick: () => handlePermanentlyDeleteDocument(document), children: t('trash:permanentlyDelete') })] })] }));
};
export default TrashDocumentsTableOptionsMenu;
