import { jsx as _jsx } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import { Select as HTMLSelect } from '@chakra-ui/react';
import { getLanguageString, locales } from '@izimi/core';
import { useIsMobile } from '@izimi/hooks';
import { userMessages } from '@izimi/modules';
import { CustomIcon } from '../icon';
import { showSuccessMessage } from '../messages';
import Select from '../select/Select';
const LanguageSelect = ({ onChange, language, submitOnSelect = true, size = 'md', }) => {
    const { i18n } = useTranslation();
    const locale = i18n.language;
    const isMobile = useIsMobile();
    const changeLanguage = (lng) => {
        if (submitOnSelect) {
            i18n.changeLanguage(lng);
            showSuccessMessage(userMessages.languageChanged().successMessage);
        }
        if (onChange) {
            onChange(lng);
        }
    };
    const currentLanguage = language ?? locale;
    const verifyLanguage = (lng) => {
        return locales.includes(lng);
    };
    const mobile = (_jsx(HTMLSelect, { icon: _jsx(CustomIcon, { name: 'chevron-down', color: 'neutral400', size: 20 }), value: currentLanguage, onChange: (e) => {
            const selectedCategory = e.currentTarget.value;
            if (verifyLanguage(selectedCategory)) {
                changeLanguage(selectedCategory);
            }
        }, children: locales.map(lang => (_jsx("option", { value: lang, children: getLanguageString(lang) }, lang))) }));
    const desktop = (_jsx(Select, { items: locales?.map(lang => ({
            id: lang,
            label: getLanguageString(lang),
        })), selectedItem: {
            id: currentLanguage,
            label: getLanguageString(currentLanguage),
        }, size: size, onSelectedItemChange: (item) => {
            if (item?.id) {
                changeLanguage(item.id);
            }
        } }));
    return isMobile ? mobile : desktop;
};
export default LanguageSelect;
