import { jsx as _jsx } from "react/jsx-runtime";
import { chakra, shouldForwardProp, useTheme } from '@chakra-ui/react';
import { BookIcon } from './custom-icons/BookIcon';
import { BookOpenIcon } from './custom-icons/BookOpenIcon';
import { ArrowDownIcon, ArrowUpCircleIcon, ArrowUpIcon, BellIcon, BoxIcon, BriefcaseIcon, BugIcon, CalendarIcon, CertificationIcon, CertifiedDocumentIcon, CheckIcon, CheckStroke, ChevronDownIcon, ChevronLeftIcon, ChevronRightIcon, ClearCircleIcon, ClearIcon, ClockIcon, CloudOffIcon, CookieIcon, CopyIcon, CopyStrokeIcon, DocumentIcon, DocumentsIcon, DownloadIcon, EditIcon, ExclamationMarkIcon, ExternalLinkSmallIcon, EyeIcon, EyeStrokeIcon, FednotLogoIcon, FileStrokeIcon, FrownIcon, GiveBackIcon, GlassesIcon, HelpCircleIcon, HomeIcon, InfoIcon, LikeIcon, LinkIcon, LogoutIcon, MailIcon, MapPinIcon, MinusIcon, PenIcon, PhoneIcon, PlusIcon, PushAcceptedIcon, PushDeclinedIcon, PushIcon, RejectIcon, RestoreIcon, ScanningForVirusIcon, Share2Icon, ShareIcon, SlideOutIcon, SSNIcon, StampIcon, TagIcon, TextIcon, TrashCheckIcon, TrashClockIcon, TrashIcon, UserAddIcon, UserArrowIcon, UserIcon, UserMinusIcon, UserOutlineExecutedIcon, UserOutlineIcon, UserOutlineRejectedIcon, UserPlusIcon, UserShareAcceptedIcon, UserShareDeclinedIcon, UserShareIcon, UsersIcon, VaultCheckIcon, VaultIcon, VaultSmallIcon, VaultTransferIcon, WalletIcon, WarningIcon, } from './custom-icons';
const CustomIcon = ({ name, color = 'primary200', secondaryColor = 'primary500', ternaryColor = 'primary600', solid = false, ...rest }) => {
    const theme = useTheme();
    const Icon = customIcons[name];
    return (_jsx(Icon, { color: theme.colors[color], secondaryColor: theme.colors[secondaryColor], ternaryColor: theme.colors[ternaryColor], solid: solid, ...rest }));
};
const chakraOptions = {
    shouldForwardProp: (prop) => {
        if (prop === 'color')
            return true;
        return shouldForwardProp(prop);
    },
};
export default chakra(CustomIcon, chakraOptions);
export const customIcons = {
    'arrow-down': ArrowDownIcon,
    'arrow-up-circle': ArrowUpCircleIcon,
    'arrow-up': ArrowUpIcon,
    'box': BoxIcon,
    'briefcase': BriefcaseIcon,
    'bug': BugIcon,
    'certification': CertificationIcon,
    'check': CheckIcon,
    'check-stroke': CheckStroke,
    'chevron-down': ChevronDownIcon,
    'chevron-left': ChevronLeftIcon,
    'chevron-right': ChevronRightIcon,
    'clear': ClearIcon,
    'clear-circle': ClearCircleIcon,
    'clock': ClockIcon,
    'cookie': CookieIcon,
    'copy': CopyIcon,
    'copy-stroke': CopyStrokeIcon,
    'document': DocumentIcon,
    'documents': DocumentsIcon,
    'download': DownloadIcon,
    'exclamation-mark': ExclamationMarkIcon,
    'eye': EyeIcon,
    'eye-stroke': EyeStrokeIcon,
    'fednot': FednotLogoIcon,
    'frown': FrownIcon,
    'give-back': GiveBackIcon,
    'glasses': GlassesIcon,
    'help-circle': HelpCircleIcon,
    'home': HomeIcon,
    'info': InfoIcon,
    'like': LikeIcon,
    'link': LinkIcon,
    'logout': LogoutIcon,
    'mail': MailIcon,
    'map-pin': MapPinIcon,
    'pen': PenIcon,
    'phone': PhoneIcon,
    'push-accepted': PushAcceptedIcon,
    'push-declined': PushDeclinedIcon,
    'push': PushIcon,
    'restore': RestoreIcon,
    'scanning-for-virus': ScanningForVirusIcon,
    'share-2': Share2Icon,
    'share': ShareIcon,
    'slide-out': SlideOutIcon,
    'SSN': SSNIcon,
    'stamp': StampIcon,
    'tag': TagIcon,
    'text': TextIcon,
    'trash': TrashIcon,
    'user-add': UserAddIcon,
    'user-arrow': UserArrowIcon,
    'user-minus': UserMinusIcon,
    'user-outline-executed': UserOutlineExecutedIcon,
    'user-outline': UserOutlineIcon,
    'user-outline-rejected': UserOutlineRejectedIcon,
    'user-plus': UserPlusIcon,
    'user-share': UserShareIcon,
    'user-share-accepted': UserShareAcceptedIcon,
    'user-share-declined': UserShareDeclinedIcon,
    'user': UserIcon,
    'users': UsersIcon,
    'vault-transfer': VaultTransferIcon,
    'vault': VaultIcon,
    'vault-check': VaultCheckIcon,
    'certified-document': CertifiedDocumentIcon,
    'cloud-off': CloudOffIcon,
    'trash-check': TrashCheckIcon,
    'trash-clock': TrashClockIcon,
    'plus': PlusIcon,
    'book': BookIcon,
    'book-open': BookOpenIcon,
    'calendar': CalendarIcon,
    'reject': RejectIcon,
    'edit': EditIcon,
    'minus': MinusIcon,
    'external-link-small': ExternalLinkSmallIcon,
    'bell': BellIcon,
    'file-stroke': FileStrokeIcon,
    'vault-small': VaultSmallIcon,
    'warning': WarningIcon,
    'wallet': WalletIcon,
};
