import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import { Divider, Flex, Grid, Text } from '@chakra-ui/react';
import { Tracker } from '@izimi/modules';
import { CustomIcon } from '../icon';
import { Link } from '../Link/Link';
import { useTracking } from '../tracking';
const DefaultIcon = _jsx(CustomIcon, { name: 'info', color: 'primary300' });
export const NabanInfoMessage = ({ message, document, Icon = DefaultIcon, ...chakraProps }) => {
    const { t } = useTranslation('shared');
    const { trackEvent } = useTracking();
    const { nabanMetadata } = document;
    const handleTracking = () => {
        trackEvent({
            category: Tracker.Category.NabanActs,
            action: Tracker.Action.ViewNabanAct,
            value: Number(nabanMetadata?.nabanDocumentId),
        });
    };
    return (_jsxs(Grid, { gridTemplateColumns: 'auto 1fr', columnGap: 4, bg: 'primary100', color: 'primary600', borderRadius: 'lg', p: 4, ...chakraProps, children: [Icon, _jsxs(Flex, { flexDir: 'column', gap: 2, children: [_jsx(Text, { children: message }), _jsx(Divider, { borderColor: 'primary200' }), _jsx(Link, { to: `/services/naban/preview?id=${nabanMetadata?.nabanDocumentId}`, fontWeight: 'semibold', textDecoration: 'underline', color: 'primary500', onClick: handleTracking, children: t('sideBarInfo.viewNaban') })] })] }));
};
