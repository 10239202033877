import { useInfiniteQuery, useMutation, useQuery } from '@tanstack/react-query';
import sub from 'date-fns/sub';
import { ApiNotificationType } from '@izimi/api';
import { useInvalidateContactListCache, useInvalidateContactRequestsCache, } from '../contacts';
import { useDocumentVirusScanStore, useUpdatePagedDocumentsCache, } from '../documents';
import { useInvalidateDocumentsSharedFromContactsListCache, useInvalidateDocumentsSharedFromNotaryListCache, useInvalidateDocumentsVaultListCache, } from '../documents/cache';
import { useInvalidateVaultInfoCache } from '../vault';
import { notificationsQueryKeys, useGetPagedNotificationsCache, useInvalidateHasUnreadNotificationsCache, useInvalidateNotificationsCache, useSetPagedNotificationsCache, } from './cache';
import { sortNotifications } from './helper';
import { getNotificationItems } from './notificationItems';
const createNotificationsModule = ({ notificationsApi, }) => {
    const usePagedNotifications = ({ pageSize = 15, dontMarkFirstPageAsViewed = false, } = {}) => {
        const { markNotificationsAsViewed } = useMarkNotificationsAsViewed();
        const { data, isLoading, error, fetchNextPage, isFetchingNextPage, hasNextPage, refetch, } = useInfiniteQuery({
            queryKey: notificationsQueryKeys.getPagedNotifications(),
            initialPageParam: 0,
            queryFn: async ({ pageParam }) => {
                const pageIndex = Number(pageParam);
                const nextNotifications = await notificationsApi.getNotifications({
                    page: Number(pageParam),
                    pageSize,
                });
                const sortedNotifications = sortNotifications(nextNotifications);
                const notViewedNotifications = sortedNotifications
                    .filter(n => !n.viewed)
                    .map(n_1 => n_1.id);
                // First page index will be marked as read with the onOpen Fn of the NotificationPopOver
                // Next pages will only be rendered if the user has the NotificationPopOver opened, so this can still happen
                if (notViewedNotifications.length &&
                    (!dontMarkFirstPageAsViewed || pageIndex > 0)) {
                    await markNotificationsAsViewed({
                        ids: notViewedNotifications,
                        pageIndex,
                    });
                }
                return sortedNotifications;
            },
            getNextPageParam: (lastPage, allPages) => lastPage.length >= pageSize ? allPages.length : undefined,
        });
        return {
            getNotificationItems,
            notifications: data?.pages.flat(),
            error,
            isLoading,
            fetchNextPage,
            isFetchingNextPage,
            hasNextPage,
            refetch,
        };
    };
    const useMarkNotificationsAsViewed = () => {
        const getPagedNotificationsCache = useGetPagedNotificationsCache();
        const setPagedNotificationsCache = useSetPagedNotificationsCache();
        const invalidateHasUnreadNotificationsCache = useInvalidateHasUnreadNotificationsCache();
        const { mutateAsync, isPending, error } = useMutation({
            mutationFn: ({ ids }) => notificationsApi.markNotificationsAsViewed(ids),
            onSuccess(_, { pageIndex }) {
                const pagedNotifications = getPagedNotificationsCache();
                if (pagedNotifications?.pages.length) {
                    const pages = pagedNotifications.pages.reduce((acc, page, idx) => {
                        if (idx === pageIndex) {
                            acc.push(page.map(n => ({ ...n, viewed: true })));
                        }
                        else {
                            acc.push(page);
                        }
                        return acc;
                    }, []);
                    setPagedNotificationsCache({
                        ...pagedNotifications,
                        pages,
                    });
                }
                invalidateHasUnreadNotificationsCache();
            },
        });
        return {
            markNotificationsAsViewed: mutateAsync,
            error,
            isPending,
        };
    };
    const useDeleteNotification = () => {
        const getPagedNotificationsCache = useGetPagedNotificationsCache();
        const setPagedNotificationsCache = useSetPagedNotificationsCache();
        const { mutateAsync, isPending, error } = useMutation({
            mutationFn: id => notificationsApi.deleteNotification(id),
            onSuccess(_, id) {
                const pagedNotifications = getPagedNotificationsCache();
                if (pagedNotifications?.pages.length) {
                    const pages = pagedNotifications.pages.reduce((acc, page) => {
                        const notification = page.find(n => n.id === id);
                        if (notification) {
                            acc.push(page.filter(n => n.id !== notification.id));
                        }
                        else {
                            acc.push(page);
                        }
                        return acc;
                    }, []);
                    setPagedNotificationsCache({
                        ...pagedNotifications,
                        pages,
                    });
                }
            },
        });
        return {
            deleteNotification: mutateAsync,
            error,
            isPending,
        };
    };
    const REFETCH_INTERVAL_2_MINUTES = 1000 * 120;
    const callFnForNotViewedNotifications = (notifications) => (types, action) => {
        const shouldInvalidate = notifications.some(n => !n.viewed && types.includes(n.type));
        if (shouldInvalidate) {
            action();
        }
    };
    const useHasUnreadNotifications = () => {
        const invalidateContactsCache = useInvalidateContactListCache();
        const invalidateContactRequestsCache = useInvalidateContactRequestsCache();
        const invalidateVaultInfoCache = useInvalidateVaultInfoCache();
        const invalidateDocumentsVaultListCache = useInvalidateDocumentsVaultListCache();
        const invalidateDocumentsSharedFromNotaryListCache = useInvalidateDocumentsSharedFromNotaryListCache();
        const invalidateDocumentsSharedFromContactsListCache = useInvalidateDocumentsSharedFromContactsListCache();
        const from = sub(new Date(), { weeks: 3 }).toISOString();
        const { data, isLoading, error } = useQuery({
            queryKey: notificationsQueryKeys.hasUnreadNotifications(),
            queryFn: () => notificationsApi.getNotifications({ from }).then((notifications) => {
                const callFnOnNotificationTypes = callFnForNotViewedNotifications(notifications);
                callFnOnNotificationTypes([ApiNotificationType.ContactRequestAccepted], invalidateContactsCache);
                callFnOnNotificationTypes([ApiNotificationType.ContactRequestReceived], invalidateContactRequestsCache);
                callFnOnNotificationTypes([ApiNotificationType.VaultTransferReceived], invalidateVaultInfoCache);
                callFnOnNotificationTypes([ApiNotificationType.PushedDocumentsReceived], invalidateDocumentsSharedFromNotaryListCache);
                callFnOnNotificationTypes([ApiNotificationType.SharedDocumentsReceived], invalidateDocumentsSharedFromContactsListCache);
                callFnOnNotificationTypes([
                    ApiNotificationType.SharedDocumentUnshared,
                    ApiNotificationType.SharedDocumentUnsharedByRecipient,
                    ApiNotificationType.SharedDocumentsAccepted,
                    ApiNotificationType.SharedDocumentsReceived,
                    ApiNotificationType.DocumentUploadedByThirdParty,
                    ApiNotificationType.DocumentSuccessfullyUploaded,
                ], invalidateDocumentsVaultListCache);
                return notifications.some(n => !n.viewed);
            }),
            refetchInterval: REFETCH_INTERVAL_2_MINUTES,
        });
        return {
            hasUnreadNotifications: data ?? false,
            error,
            isLoading,
        };
    };
    const REFETCH_INTERVAL_5_SECONDS = 1000 * 5;
    const useStartListeningForDocumentVirusScanNotifications = () => {
        const { documentIds, removeDocumentIds } = useDocumentVirusScanStore();
        const updatePagedDocumentsCache = useUpdatePagedDocumentsCache();
        const invalidateVaultInfoCache = useInvalidateVaultInfoCache();
        const invalidateHasUnreadNotificationsCache = useInvalidateHasUnreadNotificationsCache();
        const invalidateNotificationsCache = useInvalidateNotificationsCache();
        const from = sub(new Date(), { minutes: 5 }).toISOString();
        useQuery({
            queryKey: notificationsQueryKeys.pollNotifications(),
            queryFn: async () => {
                const notifications = await notificationsApi.getNotifications({ from });
                const filteredNotifications = notifications.filter(n => !n.viewed &&
                    (n.type === ApiNotificationType.DocumentSuccessfullyUploaded ||
                        n.type === ApiNotificationType.DocumentUploadFailedDueToVirus));
                if (filteredNotifications.some(n => documentIds.includes(n.data.documentId))) {
                    const idsToRemove = filteredNotifications.map(n => n.data.documentId);
                    removeDocumentIds(idsToRemove);
                    updatePagedDocumentsCache('vaultList', (allDocuments) => {
                        return allDocuments.map((document) => {
                            const notification = filteredNotifications.find(n => n.data.documentId === document.id);
                            if (notification?.type ===
                                ApiNotificationType.DocumentSuccessfullyUploaded) {
                                // New document in vault, so vault size has changed.
                                invalidateVaultInfoCache();
                                // New notifications, so invalidate notifications cache.
                                invalidateNotificationsCache();
                                invalidateHasUnreadNotificationsCache();
                                return {
                                    ...document,
                                    isBeingScanned: false,
                                };
                            }
                            if (notification?.type ===
                                ApiNotificationType.DocumentUploadFailedDueToVirus) {
                                return {
                                    ...document,
                                    isBeingScanned: false,
                                    hasVirus: true,
                                };
                            }
                            return document;
                        });
                    });
                }
                return notifications;
            },
            refetchInterval: REFETCH_INTERVAL_5_SECONDS,
            enabled: Boolean(documentIds.length),
        });
    };
    return {
        usePagedNotifications,
        useMarkNotificationsAsViewed,
        useDeleteNotification,
        useHasUnreadNotifications,
        useStartListeningForDocumentVirusScanNotifications,
    };
};
export default createNotificationsModule;
