import { buildQueryString } from '@izimi/utils';
class DocumentsApi {
    fetchService;
    version = 'v2';
    baseUrl = `/${this.version}/documents`;
    constructor(fetchService) {
        this.fetchService = fetchService;
    }
    getDocuments(params) {
        const qs = buildQueryString(params);
        return this.fetchService.get(`${this.baseUrl}${qs}`);
    }
    getDocument({ documentId }) {
        return this.fetchService.get(`${this.baseUrl}/${documentId}`);
    }
    moveDocumentsToTrash(documentIds) {
        return this.fetchService.post(`${this.baseUrl}/trash`, documentIds);
    }
    updateDocument({ documentId }, values) {
        return this.fetchService.put(`${this.baseUrl}/${documentId}`, values);
    }
    copyDocument({ documentId }) {
        return this.fetchService.post(`${this.baseUrl}/${documentId}/copy`);
    }
    shareDocuments(values) {
        return this.fetchService.post(`${this.baseUrl}/share`, values);
    }
    unshareDocuments(values) {
        return this.fetchService.post(`${this.baseUrl}/unshare`, values);
    }
    acceptRejectSharedDocuments(values) {
        return this.fetchService.post(`${this.baseUrl}/accept-reject`, values);
    }
    unTrashDocuments(documentIds) {
        return this.fetchService.post(`${this.baseUrl}/untrash`, documentIds);
    }
    /**
     * Delete the documents without going through the trash bin
     */
    permanentlyDeleteDocuments(documentIds, params) {
        const qs = buildQueryString(params);
        return this.fetchService.post(`${this.baseUrl}/delete${qs}`, documentIds);
    }
    getLabels() {
        return this.fetchService.get('/tags');
    }
    markDocumentsSeen(documentIds, seen) {
        return this.fetchService.post(`${this.baseUrl}/${seen ? 'seen' : 'unseen'}`, documentIds);
    }
    documentExtendTrashDate(documentIds) {
        return this.fetchService.post(`${this.baseUrl}/auto-deletion-restart`, documentIds);
    }
}
export default DocumentsApi;
