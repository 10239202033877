import type { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Flex, Grid, Image, Show, Stack, Text } from '@chakra-ui/react';

import { ExternalLink, LanguageSelect } from '@izimi/components';
import { showCookieConsent } from '@izimi/core';
import { useConfigurationLinks } from 'core/modules/public';

import IzimiLogo from './IzimiLogo';

import imageLoginBackground from '/src/images/login/login-bg.jpg';
import ImageLoginBackgroundMobile from '/src/images/login/login-bg-mobile.jpg';

interface Props {
  children?: ReactNode;
}

const LoginLayout = ({ children }: Props) => {
  const { t } = useTranslation('login');
  const { privacyUrl, termsAndConditionsUrl } = useConfigurationLinks();

  return (
    <Grid templateColumns={{ base: '1fr', md: '1fr 1fr' }}>
      <Show above='md'>
        <Box
          pos='relative'
          display='flex'
          alignItems='center'
          justifyContent='center'
        >
          <Flex pos='absolute' height='100vh' top='0' alignItems='center'>
            <IzimiLogo zIndex='docked' />
          </Flex>
          <Image
            src={imageLoginBackground}
            alt='man-reading-phone|woman-reading-document|man-holding-child'
            objectFit='cover'
            height='100%'
          />
        </Box>
      </Show>
      <Grid gridTemplateRows='auto 1fr auto' height='100vh'>
        <Box
          as='header'
          p={{ md: 8 }}
          justifySelf={{ base: 'auto', md: 'flex-end' }}
          pos='relative'
        >
          <Show above='md'>
            <LanguageSelect />
          </Show>
          <Show below='md'>
            <Image
              src={ImageLoginBackgroundMobile}
              alt='man-reading-phone|woman-reading-document'
              objectFit='cover'
            />
          </Show>
        </Box>
        <Flex
          as='main'
          flexDir='column'
          alignItems='center'
          alignSelf='center'
          justifySelf='center'
          mx={8}
          maxW='500px'
        >
          <Show below='md'>
            <IzimiLogo zIndex='docked' mb={8} />
          </Show>
          {children}
        </Flex>
        <Stack
          as='footer'
          color='neutral400'
          alignItems='center'
          py={6}
          px={8}
          spacing={{ base: 2, md: 4 }}
          justifySelf='center'
          direction={{ base: 'column', xl: 'row' }}
        >
          <Show below='md'>
            <LanguageSelect />
          </Show>
          <Text variant='paragraph' pb={{ base: 4, md: 0 }}>
            &copy;
            {' '}
            {new Date().getFullYear()}
            {' '}
            Fednot -
            {' '}
            {t('footer.allRightsReserved')}
          </Text>
          <ExternalLink variant='largeLink' href={privacyUrl}>
            {t('footer.privacyPolicy')}
          </ExternalLink>
          <ExternalLink variant='largeLink' href={termsAndConditionsUrl}>
            {t('footer.termsAndConditions')}
          </ExternalLink>
          <Text
            variant='largeLink'
            as='button'
            onClick={showCookieConsent}
            type='button'
          >
            {t('footer.cookies')}
          </Text>
        </Stack>
      </Grid>
    </Grid>
  );
};

export default LoginLayout;
