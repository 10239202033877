import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { IconButton } from '@chakra-ui/react';

import { FeatherIcon, useDocumentFiltering } from '@izimi/components';
import { Routes } from 'navigation/types';

const NavigationSearchIcon = () => {
  const { t } = useTranslation('shared');
  const location = useLocation();
  const navigate = useNavigate();
  const { isSearchModeActive, setIsSearchModeActive } = useDocumentFiltering();

  return (
    <IconButton
      display={{ base: 'inline-flex', md: 'none' }}
      aria-label={t('navigation.vaultSearch')}
      backgroundColor='transparent'
      variant='menuIcon'
      icon={(
        <FeatherIcon
          name='search'
          color={
            location.pathname === Routes.VaultSearch ?
              'primary500' :
              'neutral400'
          }
        />
      )}
      title={t('navigation.vaultSearch')}
      onClick={() => {
        if (location.pathname === Routes.VaultSearch) {
          navigate(Routes.Vault);
        } else {
          navigate(Routes.VaultSearch);
        }
        if (isSearchModeActive) {
          setIsSearchModeActive(false);
        }
      }}
    />
  );
};

export default NavigationSearchIcon;
