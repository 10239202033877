import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { ApiDocumentCategory } from '@izimi/api';
import {
  useDocumentFiltering,
  useModalStore,
  useTracking,
  withMessages,
} from '@izimi/components';
import {
  createExtendedDocumentsModule,
  useDocumentDeleteStore,
  useDocumentSorting,
} from '@izimi/core';
import { useActiveDocumentId, useDocumentMultiSelect } from '@izimi/hooks';
import type { DocumentEntity, PagedDocumentsArgs } from '@izimi/modules';
import {
  createDocumentsModule,
  getDocumentFilterParameters,
  Tracker,
  useModules,
} from '@izimi/modules';
import { useActiveCategory } from 'common/hooks';
import { useActiveVault } from 'common/stores/appSettingsStore';
import { documentsApi } from 'core/di';
import { Routes } from 'navigation/types';

import documentsMessages from './messages';

const hooks = createDocumentsModule({ documentsApi });
const extendedHooks = createExtendedDocumentsModule({ documentsApi });

const useDocumentFilters = () => {
  const { activeVault } = useActiveVault();
  const { citizenDocumentArgs, currentFilter } = useDocumentFiltering();
  const isActiveVault = currentFilter === 'vault' && activeVault;

  const ownerId = () => {
    if (isActiveVault) {
      return activeVault.vaultOwnerId;
    }

    if (currentFilter === 'vault') {
      return citizenDocumentArgs.params?.ownerId;
    }

    return undefined;
  };

  const params = {
    ...citizenDocumentArgs.params,
    states: activeVault ? 'INHERITED' : citizenDocumentArgs.params?.states,
    ownerId: ownerId(),
  };
  return hooks.useDocumentFilters(params);
};

const usePagedDocuments = (
  args: Parameters<typeof hooks.usePagedDocuments>['0'],
) => {
  const { activeVault } = useActiveVault();

  // handling own vault
  if (!activeVault) {
    return hooks.usePagedDocuments(args);
  }

  // handling inherited vault
  return hooks.usePagedDocuments({
    ...args,
    params: {
      ...args?.params,
      states: !args?.params?.states ? 'INHERITED' : args?.params?.states,
      ownerId:
        args?.params?.states !== 'TRASHED' ?
          activeVault?.vaultOwnerId :
          undefined,
    },
    type: 'inheritedList',
  });
};

/**
 * Use when you want the current user's owned documents
 */
const usePagedOwnedDocuments = (
  args: Parameters<typeof hooks.usePagedDocuments>['0'],
) => {
  const {
    user: { useCurrentUser },
  } = useModules();
  const { currentUser } = useCurrentUser();

  return hooks.usePagedDocuments({
    ...args,
    params: {
      ...args.params,
      ownerId: currentUser?.id,
    },
  });
};

const useHandleDeleteDocuments = () => {
  const { moveDocumentsToTrash, isPending } = hooks.useMoveDocumentsToTrash();

  const handleDeleteDocuments = useCallback(
    async (documents: DocumentEntity[]) => {
      const documentIds = documents.map(d => d.id);

      await withMessages(moveDocumentsToTrash(documentIds))(
        documentsMessages.trashDocuments({
          numberOfDocs: documents.length,
          fileName: documents[0]?.name,
        }),
      );
    },
    [moveDocumentsToTrash],
  );

  return {
    handleDeleteDocuments,
    isPending,
  };
};

const useDocumentOptions = () => {
  const { showModal, hideModal } = useModalStore();
  const { hideDeleteDocumentConfirmation } = useDocumentDeleteStore();
  const navigate = useNavigate();

  const { activeDocumentId, clearActiveDocumentId } = useActiveDocumentId();
  const { trackEvent } = useTracking();
  const {
    contacts: { usePagedContacts },
  } = useModules();

  const { handleDeleteDocuments, isPending: isLoadingDeleteDocument } =
    useHandleDeleteDocuments();
  const { downloadDocumentToFileSystem, isPending: isLoadingDownloadDocument } =
    extendedHooks.useDownloadDocumentToFileSystem();
  const { contacts, isLoading: isLoadingContacts } = usePagedContacts();
  const { copyDocument, isPending: isLoadingCopyDocument } =
    hooks.useCopyDocument();
  const { copyInheritedDocument, isPending: isLoadingCopyInheritedDocument } =
    hooks.useCopyInheritedDocument();

  const handleDeleteDocument = useCallback(
    async (document: DocumentEntity) => {
      if (hideDeleteDocumentConfirmation) {
        await handleDeleteDocuments([document]);
        if (document.id === activeDocumentId) {
          clearActiveDocumentId();
        }
        hideModal();
      } else {
        showModal({ type: 'deleteDocuments', documents: [document] });
      }
    },
    [
      activeDocumentId,
      clearActiveDocumentId,
      handleDeleteDocuments,
      hideDeleteDocumentConfirmation,
      showModal,
      hideModal,
    ],
  );

  const handleShareDocument = useCallback(
    (document: DocumentEntity) => {
      if (contacts?.length === 0) {
        showModal({ type: 'noContacts' });
        return;
      }

      showModal({
        type: 'documentShare',
        documents: [document],
        isManageFlow: document.isSharedWithOthers,
      });
    },
    [contacts?.length, showModal],
  );

  const handleManageShareDocument = useCallback(
    (document: DocumentEntity) => {
      showModal({
        type: 'documentShare',
        documents: [document],
        isManageFlow: document.isSharedWithOthers,
      });
    },
    [showModal],
  );

  const handleCopyDocument = useCallback(
    async (document: DocumentEntity) => {
      await withMessages(copyDocument(document.id))(
        documentsMessages.copyDocument(document.name),
      );
      hideModal();
    },
    [copyDocument, hideModal],
  );

  const handleCopyInheritedDocument = useCallback(
    async (document: DocumentEntity) => {
      trackEvent({
        category: Tracker.Category.InheritedDocuments,
        action: Tracker.Action.CopiedInheritedDocuments,
        value: 1,
      });
      await withMessages(copyInheritedDocument(document.id))(
        documentsMessages.copyDocument(document.name),
      );
      hideModal();
    },
    [copyInheritedDocument, hideModal, trackEvent],
  );

  const handleDownloadDocumentToFileSystem = useCallback(
    async (document: DocumentEntity) => {
      trackEvent({
        category: Tracker.Category.Vault,
        action: Tracker.Action.DownloadedDocument,
        value: 1,
      });
      await withMessages(downloadDocumentToFileSystem(document))(
        documentsMessages.downloadDocument(document.fileNameWithoutExtension),
      );
      hideModal();
    },
    [downloadDocumentToFileSystem, hideModal, trackEvent],
  );

  const handleEditDocumentName = useCallback(
    (document: DocumentEntity) => {
      showModal({ type: 'editDocumentName', document });
    },
    [showModal],
  );

  const handleEditDocumentCategories = useCallback(
    (document: DocumentEntity) => {
      showModal({ type: 'editDocumentCategories', document });
    },
    [showModal],
  );

  const handleEditDocumentLabels = useCallback(
    (document: DocumentEntity) => {
      showModal({ type: 'editDocumentLabels', document });
    },
    [showModal],
  );

  const handleDocumentHistory = useCallback(
    (document: DocumentEntity) => {
      showModal({ type: 'documentHistoryLog', document });
    },
    [showModal],
  );

  const handleDocumentVaultTransfer = useCallback(() => {
    navigate(Routes.VaultTransferOverview);
  }, [navigate]);

  return {
    handleCopyDocument,
    handleCopyInheritedDocument,
    handleDeleteDocument,
    handleDownloadDocumentToFileSystem,
    handleDocumentHistory,
    handleEditDocumentCategories,
    handleEditDocumentLabels,
    handleEditDocumentName,
    handleShareDocument,
    handleManageShareDocument,
    handleDocumentVaultTransfer,
    isLoadingContacts,
    isLoadingCopyDocument,
    isLoadingCopyInheritedDocument,
    isLoadingDeleteDocument,
    isLoadingDownloadDocument,
  };
};

const useDocumentTableDocuments = ({
  params,
  enabled = true,
}: {
  params?: PagedDocumentsArgs['params'];
  enabled?: PagedDocumentsArgs['enabled'];
} = {}) => {
  const { activeSorting } = useDocumentSorting('vault');
  const { activeCategory } = useActiveCategory();
  const { filters, searchValue, citizenDocumentArgs } = useDocumentFiltering();

  const filterParams = {
    ...citizenDocumentArgs.params,
    ...getDocumentFilterParameters(filters),
    ...params,
  };

  return usePagedDocuments({
    ...citizenDocumentArgs,
    params: {
      ...(activeCategory !== ApiDocumentCategory.All && {
        categories: activeCategory,
      }),
      sort: `${activeSorting.id},${activeSorting.desc ? 'desc' : 'asc'}`,
      documentName: searchValue || undefined,
      ...filterParams,
    },
    enabled,
  });
};

const useMultipleDocumentOptions = () => {
  const { showModal, hideModal } = useModalStore();
  const { clearDocumentSelection } = useDocumentMultiSelect();
  const { handleDeleteDocuments, isPending: isLoadingDeleteDocuments } =
    useHandleDeleteDocuments();
  const { hideDeleteDocumentConfirmation } = useDocumentDeleteStore();
  const {
    downloadDocumentToFileSystem,
    isPending: isLoadingDownloadDocuments,
  } = extendedHooks.useDownloadDocumentToFileSystem();
  const {
    documents: { useCopyMultipleInheritedDocuments },
  } = useModules();
  const {
    copyMultipleInheritedDocuments,
    isPending: isLoadingCopyInheritedDocument,
  } = useCopyMultipleInheritedDocuments();

  const handleShareMultipleDocuments = useCallback(
    async (documents: DocumentEntity[]) => {
      showModal({
        type: 'documentShare',
        documents,
      });
    },
    [showModal],
  );

  const handleDeleteMultipleDocuments = useCallback(
    async (documents: DocumentEntity[]) => {
      if (hideDeleteDocumentConfirmation) {
        await handleDeleteDocuments(documents);
        clearDocumentSelection();
        hideModal();
      } else {
        showModal({ type: 'deleteDocuments', documents });
      }
    },
    [
      hideDeleteDocumentConfirmation,
      handleDeleteDocuments,
      clearDocumentSelection,
      hideModal,
      showModal,
    ],
  );

  const handleDownloadMultipleDocuments = useCallback(
    (documents: DocumentEntity[]) => {
      documents.forEach(async doc =>
        withMessages(downloadDocumentToFileSystem(doc))(
          documentsMessages.downloadDocument(doc.fileNameWithoutExtension),
        ),
      );
    },
    [downloadDocumentToFileSystem],
  );

  const handleCopyMultipleInheritedDocuments = useCallback(
    (documents: DocumentEntity[]) => {
      withMessages(copyMultipleInheritedDocuments(documents.map(d => d.id)))(
        documentsMessages.copyMultipleDocuments(documents.length.toString()),
      );
    },
    [copyMultipleInheritedDocuments],
  );

  return {
    handleShareMultipleDocuments,
    handleDeleteMultipleDocuments,
    handleDownloadMultipleDocuments,
    handleCopyMultipleInheritedDocuments,
    isLoadingCopyInheritedDocument,
    isLoadingDeleteDocuments,
    isLoadingDownloadDocuments,
  };
};

export const documentsModule = {
  ...hooks,
  ...extendedHooks,
  useDocumentFilters,
  usePagedDocuments,
  usePagedOwnedDocuments,
  useHandleDeleteDocuments,
  useDocumentOptions,
  useDocumentTableDocuments,
  useMultipleDocumentOptions,
};

export type DocumentsModule = typeof documentsModule;
