import { groupBy } from '@izimi/utils';
export const getCitizenContacts = (contacts = []) => sortCitizenContacts(contacts.filter((c) => c.contactType === 'CITIZEN'));
export const getNotaryContacts = (contacts = []) => sortNotaryContacts(contacts.filter((c) => c.contactType === 'STUDY'));
export const sortCitizenContacts = (contacts) => [...contacts].sort((c1, c2) => c1.firstName.localeCompare(c2.firstName) ||
    c1.lastName.localeCompare(c2.lastName) ||
    c1.emailAddress.localeCompare(c2.emailAddress));
export const sortNotaryContacts = (contacts) => [...contacts].sort((c1, c2) => c1.studyName.localeCompare(c2.studyName) ||
    c1.emailAddress.localeCompare(c2.emailAddress));
export const getContactName = (contact) => {
    if (contact.contactType === 'CITIZEN') {
        return `${contact.firstName} ${contact.lastName}`;
    }
    if (contact.contactType === 'STUDY') {
        return contact.studyName;
    }
    return '';
};
export const filterContacts = (contacts = [], value) => contacts.filter((contact) => {
    const filterValue = value.toLowerCase();
    if (contact.contactType === 'CITIZEN') {
        return (contact.firstName.toLowerCase().includes(filterValue) ||
            contact.lastName.toLowerCase().includes(filterValue));
    }
    if (contact.contactType === 'STUDY') {
        return contact.studyName.toLowerCase().includes(filterValue);
    }
    return false;
});
export const getContactSections = (contacts) => {
    const groupedContacts = groupBy(contacts, c => getContactName(c).slice(0, 1).toUpperCase());
    return Object.keys(groupedContacts).reduce((sections, groupKey) => {
        sections.push({
            title: groupKey,
            data: groupedContacts[groupKey],
        });
        return sections;
    }, []);
};
