import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box } from '@chakra-ui/react';
import { useModules } from '@izimi/modules';
import { FeatherIcon } from '../icon';
export const NavigationNotificationsIcon = () => {
    const { notifications: { useHasUnreadNotifications }, } = useModules();
    const { hasUnreadNotifications } = useHasUnreadNotifications();
    return (_jsxs(Box, { pos: 'relative', children: [_jsx(Box, { w: 3, h: 3, pos: 'absolute', rounded: 'lg', top: '1px', right: 0, display: 'flex', alignItems: 'center', justifyContent: 'center', children: hasUnreadNotifications ?
                    (_jsx(Box, { w: 2, h: 2, bg: 'secondary400', rounded: 'lg' })) :
                    null }), _jsx(FeatherIcon, { name: 'bell', stroke: 'current' })] }));
};
