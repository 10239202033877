import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { forwardRef, useState } from 'react';
import { Box, Flex, Text, Textarea } from '@chakra-ui/react';
export const TextArea = forwardRef(({ maxCharacters = Infinity, ...rest }, ref) => {
    const [value, setValue] = useState(rest.value);
    return (_jsxs(Flex, { flexDir: 'column', alignItems: 'flex-end', children: [_jsx(Textarea, { ...rest, ref: ref, value: value, onChange: (e) => {
                    if (e.target.value.length <= maxCharacters) {
                        setValue(e.target.value);
                        rest.onChange?.(e);
                    }
                } }), _jsx(Box, { as: Text, variant: 'smallSemibold', color: 'neutral400', children: `${(value?.toString() ?? '').length}/${maxCharacters}` })] }));
});
