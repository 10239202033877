import { useCallback } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
const useQueryParams = () => {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const { pathname } = useLocation();
    const updateQueryParams = useCallback((updates) => {
        const params = new URLSearchParams(searchParams);
        updates.forEach(({ key, value }) => {
            if (searchParams.has(key) && value) {
                params.set(key, String(value));
            }
            else if (searchParams.has(key) && !value) {
                params.delete(key);
            }
            else if (!searchParams.has(key) && value) {
                params.set(key, String(value));
            }
        });
        navigate({
            pathname,
            search: params.toString(),
        });
    }, [searchParams, pathname, navigate]);
    return {
        params: Object.fromEntries(searchParams),
        updateQueryParams,
    };
};
export default useQueryParams;
