import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import { IconButton, Menu, MenuButton, MenuList, Portal, } from '@chakra-ui/react';
import CustomIcon from '../icon/CustomIcon';
import NavigationItem from './NavigationItem';
const NavigationHelpMenu = ({ items, }) => {
    const { t } = useTranslation('shared');
    return (_jsxs(Menu, { isLazy: true, placement: 'right-end', children: [_jsx(MenuButton, { as: IconButton, variant: 'menuIcon', display: { base: 'none', md: 'inline-flex' }, "aria-label": t('navigation.help'), backgroundColor: 'transparent', icon: (_jsx(CustomIcon, { name: 'help-circle', color: 'current', secondaryColor: 'current', unfilled: true })), title: t('navigation.help') }), _jsx(Portal, { children: _jsx(MenuList, { rootProps: {
                        top: 0,
                        left: 0,
                    }, children: items.map(item => (_jsx(NavigationItem, { type: 'menuItem', to: item.href, label: item.label, icon: _jsx(CustomIcon, { name: item.customIconName }), onClick: item.onClick }, item.label))) }) })] }));
};
export default NavigationHelpMenu;
