import { Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import { createInstance, MatomoProvider } from '@jonkoops/matomo-tracker-react';
import { useCookieConsent } from '@izimi/core';
import PageTracker from './PageTracker';
const TrackingProvider = ({ children, config, cookieUrl }) => {
    const { preferences } = useCookieConsent(cookieUrl);
    const hasConsent = Boolean(preferences?.cookieOptions.find(opt => opt.id === 'statistics')?.isEnabled);
    if (!hasConsent || !config) {
        return _jsx(_Fragment, { children: children });
    }
    const matomoInstance = createInstance({
        urlBase: config.url,
        siteId: config.siteId,
        trackerUrl: config.trackerUrl,
        srcUrl: config.srcUrl,
        disabled: false,
        heartBeat: {
            active: true,
            seconds: 30,
        },
        linkTracking: false,
    });
    return (_jsx(MatomoProvider, { value: matomoInstance, children: _jsx(PageTracker, { children: children }) }));
};
export default TrackingProvider;
