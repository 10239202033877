import { useRef, useState } from 'react';
import { useMutation } from '@tanstack/react-query';
import { useDocumentVirusScanStore, useInvalidateDocumentsVaultListCache, useInvalidateDocumentsVaultTransferCache, useInvalidateVaultPreferencesCache, } from '@izimi/modules';
import { downloadBlobToFileSystem } from '../../helpers/file';
const createExtendedDocumentsModule = ({ documentsApi, }) => {
    const useDownloadDocumentToFileSystem = () => {
        const { mutateAsync, isPending, error } = useMutation({
            mutationFn: document => documentsApi.downloadDocument({ documentId: document.id }),
            onSuccess: (data, document) => {
                downloadBlobToFileSystem(data, document.name);
            },
        });
        return {
            downloadDocumentToFileSystem: mutateAsync,
            error,
            isPending,
        };
    };
    const useDownloadDocument = () => {
        const { mutateAsync, isPending, error } = useMutation({
            mutationFn: params => documentsApi.downloadDocument(...params),
        });
        return {
            downloadDocument: mutateAsync,
            error,
            isPending,
        };
    };
    const useUploadDocument = () => {
        const { addDocumentId } = useDocumentVirusScanStore();
        const invalidatePagedDocumentsCache = useInvalidateDocumentsVaultListCache();
        const invalidateVaultPreferencesCache = useInvalidateVaultPreferencesCache();
        const invalidateDocumentsVaultTransferCache = useInvalidateDocumentsVaultTransferCache();
        const [progress, setProgress] = useState(0);
        const controller = useRef(new AbortController()).current;
        const cancelUpload = () => controller.abort();
        const { mutateAsync, isPending, error } = useMutation({
            mutationFn: values => documentsApi.uploadDocument(values, ({ loaded, total }) => setProgress((loaded / total) * 100), controller.signal),
            onSuccess(document) {
                addDocumentId(document.id);
                invalidatePagedDocumentsCache();
                invalidateDocumentsVaultTransferCache();
                invalidateVaultPreferencesCache();
            },
        });
        return {
            uploadDocument: mutateAsync,
            cancelUpload,
            progress,
            error,
            isPending,
        };
    };
    return {
        useDownloadDocumentToFileSystem,
        useDownloadDocument,
        useUploadDocument,
    };
};
export default createExtendedDocumentsModule;
