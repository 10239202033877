import { jsx as _jsx } from "react/jsx-runtime";
import { ErrorBoundary } from 'react-error-boundary';
import * as Sentry from '@sentry/react';
import ErrorPage from './ErrorPage';
const ReactErrorBoundary = ({ children, rerouteTo, t }) => {
    const defaults = {
        title: 'Unexpected error',
        message: 'Please refresh (f5) your browser to resolve the issue',
        button: 'Return home',
    };
    const Fallback = (_jsx(ErrorPage, { title: t('boundary.title') ?? defaults.title, message: t('boundary.message') ?? defaults.message, buttonLabel: t('boundary.button') ?? defaults.button, buttonDestination: rerouteTo, withButton: true }));
    const onError = (error, errorInfo) => {
        Sentry.withScope((scope) => {
            scope.setExtra('info', errorInfo);
            Sentry.captureException(error);
        });
    };
    return (_jsx(ErrorBoundary, { fallback: Fallback, onError: onError, children: children }));
};
export default ReactErrorBoundary;
