import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Flex, Grid, Text } from '@chakra-ui/react';
import { FeatherIcon } from '../icon';
const DefaultIcon = _jsx(FeatherIcon, { name: 'info', color: 'primary500' });
export const StatusMessage = ({ title, message, variant = 'info', Icon = DefaultIcon, children, ...chakraProps }) => {
    const style = {
        warning: {
            bg: 'secondary100',
            color: 'secondary600',
        },
        info: {
            bg: 'primary100',
            color: 'primary600',
        },
    };
    return (_jsxs(Grid, { gridTemplateColumns: 'auto 1fr', columnGap: 4, bg: style[variant].bg, color: style[variant].color, borderRadius: 'lg', p: 4, ...chakraProps, children: [Icon, _jsxs(Flex, { flexDir: 'column', children: [_jsx(Text, { variant: 'heading5', mb: 1, children: title }), message ? _jsx(Text, { children: message }) : null, children] })] }));
};
