import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { createStandaloneToast } from '@chakra-ui/toast';
import theme from '@izimi/theme';
import Message from './Message';
const { toast, ToastContainer } = createStandaloneToast({
    theme,
    defaultOptions: { duration: 3000, position: 'top' },
});
export { ToastContainer };
export const showSuccessMessage = (options) => toast({
    render: props => (_jsx(Message, { ...props, title: options.message, status: 'success', icon: options.icon ?? 'check-square' })),
});
export const showErrorMessage = (options) => toast({
    render: props => (_jsx(Message, { ...props, title: options.message, status: 'error', icon: options.icon ?? 'alert-triangle' })),
});
export const withMessages = (fn) => async ({ initialMessage, successMessage, errorMessage, } = {}) => {
    try {
        if (initialMessage) {
            showSuccessMessage(initialMessage);
        }
        const res = await fn;
        if (successMessage) {
            showSuccessMessage(typeof successMessage === 'function' ?
                successMessage(res) :
                successMessage);
        }
        return res;
    }
    catch (err) {
        if (errorMessage) {
            showErrorMessage(typeof errorMessage === 'function' ? errorMessage(err) : errorMessage);
        }
        throw err;
    }
};
export const useWithMessages = (hook) => (messages) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [initialShown, setInitialShown] = useState(false);
    const { error, isSuccess, ...rest } = hook;
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
        if (!initialShown && messages?.initialMessage) {
            showSuccessMessage(messages.initialMessage);
            setInitialShown(true);
        }
        if (isSuccess && messages?.successMessage) {
            showSuccessMessage(typeof messages.successMessage === 'function' ?
                messages.successMessage() :
                messages.successMessage);
        }
        if (error && messages?.errorMessage) {
            showErrorMessage(typeof messages.errorMessage === 'function' ?
                messages.errorMessage(error) :
                messages.errorMessage);
        }
    }, [error, isSuccess, messages, initialShown]);
    return {
        error,
        isSuccess,
        ...rest,
    };
};
