import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { memo, useCallback } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Box, chakra, CloseButton, Flex, Spinner, Text, } from '@chakra-ui/react';
import { ApiRole } from '@izimi/api';
import { isUserRole, useModules } from '@izimi/modules';
import { NotificationTime } from './NotificationTime';
const NotificationListItem = ({ notification, Icon, messageValues, messageKey, messageComponents, children, onDeleteNotification, }) => {
    const { timestamp } = notification;
    const { t } = useTranslation();
    const { notifications: { useDeleteNotification }, user: { useCurrentUser }, } = useModules();
    const { deleteNotification, isPending } = useDeleteNotification();
    const { currentUser } = useCurrentUser();
    const handleDeleteNotification = useCallback(() => {
        deleteNotification(notification.id);
        onDeleteNotification();
    }, [deleteNotification, notification.id, onDeleteNotification]);
    const components = () => {
        if (messageComponents) {
            return messageComponents;
        }
        if (messageValues) {
            return Object.keys(messageValues).map(m => (_jsx(chakra.span, { fontWeight: '600' }, m)));
        }
        return undefined;
    };
    // Only citizens can dismiss actionable notifications
    const canDismiss = !children ||
        (children && currentUser && isUserRole(ApiRole.Citizen, currentUser.roles));
    return (_jsxs(Flex, { as: 'article', flexDirection: 'row', alignItems: 'stretch', justifyContent: 'center', py: 3, px: 1, mb: 3, borderRadius: 'md', transition: '0.2s', _hover: { backgroundColor: 'neutral100' }, _focusWithin: { backgroundColor: 'neutral100' }, children: [_jsx(Box, { ml: 1, children: Icon }), _jsxs(Box, { flex: 1, mx: 3, children: [_jsx(Text, { as: 'h2', wordBreak: 'break-word', children: _jsx(Trans, { i18nKey: messageKey, values: messageValues, components: components(), t: t }) }), _jsx(NotificationTime, { timestamp: timestamp }), children] }), _jsx(Flex, { alignItems: 'start', children: isPending ?
                    (_jsx(Spinner, { color: 'primary300', size: 'sm', p: 1, mt: 2, mr: 4 })) :
                    (canDismiss && (_jsx(CloseButton, { color: 'neutral300', onClick: handleDeleteNotification, title: t('notifications:dismiss'), "aria-label": t('notifications:dismiss'), mr: 2 }))) })] }));
};
export default memo(NotificationListItem);
