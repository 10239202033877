import { jsx as _jsx } from "react/jsx-runtime";
import { NavLink as RouterLink } from 'react-router-dom';
import { Link as ChakraLink } from '@chakra-ui/react';
export const Link = ({ children, activeStyle, ...props }) => {
    return (
    // @ts-expect-error okay
    _jsx(ChakraLink
    // @ts-expect-error okay
    , { 
        // @ts-expect-error okay
        as: RouterLink, role: 'link', _activeLink: activeStyle, ...props, children: children }));
};
