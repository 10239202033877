import type { BackendModule } from 'i18next';

import { initializeI18n, namespaces } from '@izimi/core';

const read: BackendModule['read'] = async (language, namespace, callback) => {
  const json = namespaces.includes(namespace) ?
    await import(
      `../../../../../shared/izimi-i18n/locales/${language}/${namespace}.json`
    ) :
    await import(`./locales/${language}/${namespace}.json`);
  callback(null, json);
};

initializeI18n(read, { logMissing: import.meta.env.DEV });
